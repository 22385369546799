import React from "react";
import "./footerComponent.css";

function Footer(props, navigation) {
    return (
        <div className="footerComponent container-fluid ">
            <div className="row footerItem align-items-center">
                <h
                    className="footerText1 col"
                    rel="_blank"
                    onPress={() => navigation.navigate('KullaniciSozlesmesi')}
                >
                    Kullanıcı Sözleşmesi
                </h>
                <div className="footerIconComponent col">
                    <img
                        src={props.footerLogo}
                        alt="Footer Logo"
                        className="FooterIcon"
                    />
                    <p className="footerIconText">{props.footerLogoText}</p>
                </div>
                <h
                    className="footerText2 col"
                    href="./Aydinlatma-Metni"
                    onClick={() => {
                        return <aydinlatma />
                    }}
                    rel="_blank"
                >
                    Aydınlatma Metni
                </h>
            </div>

            <img src={props.footerBackground} className="footerBackground" alt="Footer Background" />
        </div>
    );
}
export default Footer;
