import React from 'react'
import "./terms.css"

export default function AydinlatmaMetni({ navigation }) {
    return (
        <div className="container-fluid py-5 termsBack"  >

            <ul>
                <h2> CHEETAH KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİNE DAİR AYDINLATMA  METNİ</h2>
                <p> CHEETAH ailesi (CHEETAH OTOMASYON YAZILIM ARGE VE ULAŞIM HİZMETLERİ LİMİTED ŞİRKETİ) olarak, Android ve iOS mobil uygulamamızı kullanan siz değerli kullanıcılarımızın kişisel verilerinin 6698 sayılı Kişisel Verilerin Korunması Kanunu’na (“Kanun”) ‘a uygun olarak işlenmesine büyük önem vermekteyiz. Bununla birlikte, bu verilerin toplanması, işlenmesi ve paylaşımı hususunda şeffaf olacağımızı ve verilerinizin güvenliğini azami şekilde sağlayacağımızı taahhüt ederiz.</p>

                <p>Kişisel verileriniz, şirketimiz tarafından, veri sorumlusu sıfatıyla, kanunda yer alan veri işleme şartların varlığı halinde Kanun’da belirtilen şekilde işlenebilecektir. Kanuni veri işleme şartlarının doğmadığı hallerde, açık rıza gerektiren prosedürler bakımından açık rızanız alınacak, mevzuatın izin verdiği durumlarda veya açık rıza alınması gereken prosedürlerin mevcudiyeti durumunda açık rızanızın alınması halinde yurt içinde ve dışında mukim 3. kişilerle paylaşabilecek, sınıflandırabilecek ve Kanun’da belirtilen diğer şekillerde işleyebilecektir.</p>

                <p>Kişisel verileriniz Şirketimiz tarafından, (i) hukuka ve dürüstlük kuralının öngördüğü biçimce, (ii) işlenme amaçları ile bağlantılı, sınırlı ve ölçülü olarak, (iii) doğru ve güncel olarak, (iv) belirli açık ve meşru amaçlar ile işlenecektir.</p>

                <p> İşbu aydınlatma metni ile, şirketimizce verilen hizmet çerçevesinde, kişisel verilerinizin neden işlendiği, hangi kişisel verilerinizin işlendiği, söz konusu kişisel verileri işlemekteki maksadımız, kişisel verilerinizin güvenliğini nasıl sağladığımız ve mevzuat gereğince haklarınızın neler olduğu ve bu hakları nasıl kullanabileceğiniz konularında siz değerli kullanıcılarımızı bilgilendirmek istiyoruz.</p>

                <p>CHEETAH Kullanıcıların tamamı, CHEETAH çalışanları, hissedarları ve iş ortakları CHEETAH KVKK Politikası’na ve aydınlatma metninde belirtilen tüm prensiplere uygun davranmakla mükelleftir.</p>

                <p>Reşit olmayanlar hakkında: CHEETAH Kullanıcı Sözleşmesi uyarınca reşit olmayanlar CHEETAH Kullanıcısı olamazlar. 15 yaşının altındaysanız lütfen CHEETAH uygulamasını kullanmayın. 15 yaşın altında bir kişinin ebeveyni tarafından çocuğun verilerini CHEETAH’a ilettiği tespit edilirse lütfen bu durumu derhal CHEETAH’a bildirin. 15 yaşın altında bir kişinin verilerinin toplandığı tespit edilirse tarafımızca silinmesi için gerekli adımlar derhal atılacaktır.</p>

                <p> Kullanıcılar, bu Politika’ya konu bilgilerinin tam, doğru ve güncel olduğunu, bu bilgilerde herhangi bir değişiklik olması halinde bunları derhal CHEETAH’a bildireceklerini kabul ve taahhüt ederler. Aksi takdirde doğacak zararlardan CHEETAH’ın herhangi bir sorumluluğu doğmayacaktır.</p>
                <p>   Bu metinde özetle aşağıda sıralanan soruların cevapları ayrıntılı şekilde izah edilecektir.</p>
                <ul>
                    <li>  Kişisel verilerinizi ne amaçla ve nasıl toplarız?(İşleme Amacı)</li>
                    <li>  	Hangi verilerinizi toplarız?(Veri Kategorileri)</li>
                    <li>  	Kişisel Verilerinizi hangi hukuki sebebe dayanarak işleriz? (Hukuki Sebep)</li>
                    <li>  	Veri Güvenliği Nasıl Sağlanır?</li>
                    <li>  	Kişisel verilerinizi ne amaçla 3.kişilere aktarabiliriz?</li>
                    <li>   	Kişisel verilerinizi ne süreyle depolarız?</li>
                    <li>   	Aydınlatma Metni’nin güncellenmesi ve bildirim usulü 6698 sayılı Kişisel Verilerin Korunması Kanunu’na (“Kanun”)  göre haklarınız nelerdir ve bu hakları nasıl kullanırsınız?</li>
                </ul>
            </ul>
            <br />

            <ul>
                <h2>1.Kişisel Verilerinizi Ne Amaçla Topluyoruz?</h2>
                <p>CHEETAH ailesi olarak, veri sorumlusu sıfatıyla; öneri, şikayet ve sorunlarınızı kayıt altına alabilmek ve hizmetin gereği gibi ifasını sağlamak amacıyla; kullanıcı başvurunuz ve hizmet talepleriniz sırasında, mobil uygulamamızı ve internet sitemizi ziyaretiniz esnasında internet sitemizin platformunun kullanıcıyı otomatik olarak tanımasına tarayan çerez sistemi adındaki tanımlama sistemi gibi kaynaklar vasıtasıyla, otomatik yahut sair yöntemlerle Kanun’a uygun olarak ve bu hükümler çerçevesinde verilerinizi toplar, işler ve aktarırız.</p>

                <p>Kişisel verileriniz, CHEETAH tarafından;
                    Kullanıcı kaydınızın alınması,
                    Hizmetin gereği gibi ifası için müşteri ile iletişimin sağlanması,
                    Kullanıcıya çağrı merkezi aracılığıyla verilen destek süreçleri,
                    Kullanıcıların memnuniyetinin ve şikayetlerinin belgelendirilebilir olması,
                    Hizmetin ifası ve sözleşme ve politika değişiklikleri hususunda gerekli bilgilendirmenin yapılması,
                    Araçlara bakım ve desteğin zamanında ve gereği gibi yapılması ve şirket malvarlığının korunması,
                    Ticari faaliyetlerin yürütülmesi, ticari iş ve stratejilerin belirlenmesi ve uygulanması, şirket içi raporlama ve iş geliştirme faaliyetlerinin gerçekleştirilmesi,
                    CHEETAH ve CHEETAH ile iş ilişkisi içinde olan kişilerin hukuki ve ticari güvenliğinin temininin sağlanması ve bu kapsamda tarafınıza gerekli bilgilendirmelerin yapılması,
                    Yasal yükümlülüklerin ifasının sağlanması,
                    Veri kayıplarının önlenmesi
                    Tarafınızın kullanıcı adı ve şifresini kullanarak giriş yaptığı mecralarda yer alan kişisel verileriniz, tercihleriniz, işlemleriniz ile gezinme süreniz ve detaylarını elde edilen diğer verilerle birlikte işleyebilmek için toplanmaktadır.</p>

                <p>  Kişisel Verilerin Toplanma Yöntemi: Kişisel verileriniz; CHEETAH tarafından kural olarak elektronik ortamda toplanmaktadır. Bununla birlikte, sözlü ve yazılı çağrı merkezi şikayetleriniz ve bunların belgelendirilmesi sürecinde yahut KVKK 11.maddesi uyarınca yaptığınız başvurular sırasında da kişisel verileriniz işlenebilmekte, fiziksel dosyalarda ve elektronik ortamda saklanmaktadır.</p>
            </ul>
            <br />

            <ul>
                <h2>2.Hangi Kişisel Verileriniz Kayıt Altına Alınır?</h2>

                <p>Veri Kategorileri:</p>
                <p>Kimlik Verileri: Ad, soyad bilgileri, 15 yaşından büyük olduğuna ilişkin bilgi; Kullanıcı kaydınızın oluşturulması, sözleşmenin kurulması için gerekli yaş şartını sağlayıp sağlamadığınızın tespiti, hizmetin gereği gibi ifasını sağlamak, muhasebe işlemleri ve yukarıda sayılan diğer işleme amaçlarıyla kimlik verileri kategorisinde belirtilen kişisel verileriniz şirketimizce işlenmektedir.</p>
                <p>   İletişim Verileri: Telefon numaranız, adres bilgileriniz, e-posta adresiniz; Hizmetin gereği gibi ifası için sizlerle iletişim kurmak, şikayet ve başvurularınızı işleme almak ve bu başvuruların belgelendirilebilir olması, KVKK 11. Maddesi kapsamında yapılan başvuruların sonuçlarını bildirmek, araçlara bakım ve desteğin zamanında ve gereği gibi yapılabilmesi, hizmetin ifası sırasında sözleşme ve politika değişiklikleri hakkında gerekli bilgilendirmenin yapılması, muhasebe süreçlerinde evrak üzerinde bulunması hallerinde iletişim kategorisinde belirttiğimiz kişisel verileriniz şirketimiz tarafından işlenmektedir.</p>

                <p>      Lokasyon Verileri: Hizmetin ifası sırasında elde edeceğimiz GPS verisi gibi konumunuza ilişkin veriler; Hizmetin gereği gibi ifası için araçlara bakım ve hizmetin zamanında yapılabilmesi, şirket malvarlığının korunması, yasal prosedürlerin ortaya çıkması halinde hukuki delil mahiyeti bulunması nedenleriyle şirket araçlarını kullandığınız sırada konum verileriniz şirketimiz tarafından işlenmektedir.</p>

                <p>        Finansal Veriler: Faturada yer alan kişisel veriler; Muhasebe süreçlerinde yasal yükümlülüğün ifası amacıyla saklanması gereken faturalarda bulunan finansal verileriniz. KULLANICI’nın kart bilgileri CHEETAH tarafından saklanmaz. Ödeme altyapısı iyzico tarafından sağlanmaktadır. KULLANICI, kimlik verileri ve ödeme bilgileri şirketin iş ortağı ödeme kuruluşu iyzico’ya aktarılmaktadır.</p>

                <p>        Diğer Veriler:</p>

                <p>         Kullanıcı Bilgileri: Kullanıcı kimlik numarası, hizmeti aldığınız tarih ve saate ilişkin veriler, şikayet kayıtlarınız, önerileriniz, site ve mobil uygulama üzerinde arama yaparken kullandığınız terimler, kullanım sırasında oluşan hatalar, ödemelere ilişkin bilgi ve durumlar, hizmetten yararlanma sıklığınız, hedefleme bilgileri ve çerez kayıtları gibi veriler; Ticari faaliyetlerin yürütülmesi, ticari iş ve stratejilerin belirlenmesi ve uygulanması, şirket içi raporlama ve iş geliştirme faaliyetlerinin gerçekleştirilmesi, müşteri işlemlerinin delil mahiyeti ve CHEETAH ve CHEETAH ile iş ilişkisi içerisinde olan kişilerin hukuki ve ticari güvenliğinin sağlanması ile yasal yükümlülüklerin ifası, veri kayıplarının önlenmesi gibi nedenlerle yukarıda sayılan kullanıcı bilgileriniz işlenmektedir.</p>

                <p>         İşlem Güvenliği Bilgisi:  Kimliği doğrulamak için ve hesaba erişimi sağlamak için kullanılan parola ve parola ipuçları, kullanıcı adı; İşlem güvenliğinin sağlanması, yetkisiz erişimin önlenmesi, veri kayıplarının önlenmesi gibi nedenlerle şirketimiz tarafından işlem güvenliği bilgileriniz işlenmektedir.</p>

                <p>         Risk Yönetimi Bilgisi: IP adresi, Kullandığı CHEETAH cihazının ve akıllı telefonun seri numarası ve modeli, ülke bilgisi; İşlem güvenliğinin sağlanması, yetkisiz erişimin önlenmesi, veri kayıplarının önlenmesi gibi nedenlerle şirketimiz tarafından risk yönetimi bilgileriniz işlenmektedir.</p>

                <p>         Çerezler aracılığıyla toplanan veriler: Site kullanımları, deneyimleri, site içerisindeki sayfalara ilişkin tercihlere ilişkin istatistiksel bilgiler (Ayrıntılı bilgi için Çerez Politikası’nı inceleyiniz.)</p>

                <p>        Bu verilerinizi muhafaza ederken Kanun’un 4. Maddesinin 2. Fıkrasında belirtildiği gibi ilgili Kanunlara ve dürüstlük kuralına uyacağımızı ve verilerinizi doğru ve gerektiğinde güncel tutacağımızı beyan ve taahhüt ederiz. Lütfen herhangi bir değişiklik halinde tarafımızı gecikmeksizin bu konuda bilgilendirin.</p>
            </ul>
            <br />

            <ul>
                <h2>     3. Kişisel Verilerinizi hangi hukuki sebebe dayanarak işleriz?</h2>
                <p>Kişisel verilerinizi Kanun’un 5. Maddesi 2.fıkra uyarınca, kanunlarda açıkça öngörülmesi, fiili rızanızı açıklayamayacak durumda olmanız halinde sizin veya 3.bir kişinin hayat ve beden bütünlüğünü sağlamak için zorunlu olması, sözleşmenin tarafları için sözleşmenin kurulması ve ifası için zorunlu olması, şirketimiz hukuki yükümlülüklerini yerine getirebilmek için zorunlu olması, tarafınızca alenileştirilmiş olması, bir hakkın tesisi, kullanılması ve korunması için zorunlu olması ve siz kullanıcılarımızın temel hak ve özgürlüklerine zarar vermemek kaydıyla meşru menfaatlerimiz için veri işlemenin zorunlu olması hallerinde yukarıda sayılan veri kategorilerinde belirtilen kişisel verilerinizi, açık rızanıza başvurmaksızın işleyebiliriz.</p>
                <p>Özel nitelikli kişisel veriler hariç olmak üzere, kişisel verilerinizi işlerken yukarıdaki hukuki sebeplerin yer alması halinde ayrıca açık rızanıza başvurulmaz. Kanunda yer alan hukuki sebepler kapsamına girmemesi halinde, kişisel verilerinizin işlenebilmesi için, ayrıca açık rızanıza başvurulacak, ancak rıza vermeniz halinde bu kişisel veriler şirketimiz tarafından işleme faaliyetine tabi tutulabilecektir.</p>
            </ul>
            <br />

            <ul>
                <h2>     4.Veri Güvenliği Nasıl Sağlanır?</h2>
                <p>  CHEETAH, ilgili mevzuatta belirlenen veya bu Politika ile ifade edilen şartlarda, kişisel verilerinize hukuka aykırı olarak erişilmemesini ve verilerin hukuka aykırı olarak işlenmemesini sağlamak için gerekli teknik ve idari güvenlik önlemlerini almayı, denetim ve kontrolleri sağlamayı taahhüt eder.</p>
                <p>Yine, kişisel verilerinizin tutulduğu sistemlerin siber güvenliğinin sağlanması için gerekli yazılımlar kullanılmakta, kişisel veri güvenliğinin takibi düzenli olarak bilişim danışmanlarımızca yapılmaktadır. Kişisel verileriniz yedeklenmektedir. Kişisel verilerinizi içeren ortamların güvenliği sağlanmıştır. Kişisel verilerinize yetkisiz erişimler engellenmiştir. Kullanıcıların verileri Secure Socket Layer teknolojisi kullanılarak doğrulama ve şifreleme yöntemleri ile korunmaktadır.</p>
                <p>Ancak, CHEETAH, Site veya mobil uygulama üzerinden başka uygulamalara ve 3.şahıslara link verilmesi halinde, uygulamaların gizlilik politikaları ve içeriklerine yönelik herhangi bir sorumluluk taşımamaktadır.</p>
                <p>Bununla birlikte, CHEETAH, hiçbir bilişim sisteminin mutlak surette güvenli olmadığını hatırlatır, kullanıcının ihmalinden yahut 3.kişilerin eylemlerinden doğan neticelerden sorumlu olmadığını bildirir.</p>
                <p> İdari tedbirler kapsamında, kişisel verilerinizin korunması için; kurumsal politikalar ve prosedürler yürürlüğe sokulmuştur. Çalışanlara ve hissedarlara farkındalık eğitimleri periyodik olarak uygulanmaya başlanmış, çalışanlarla gizlilik taahhütnameleri yapılmıştır.</p>
                <p>Kurum içi periyodik rastgele denetimler yapılmakta, risk analizleri yapılmakta, kişisel verilerin korunması hukuku özelinde çalışan hukukçulardan ve bilişim şirketlerinden danışmanlık alınmaktadır. İşyerinde kişisel veri barındıran fiziki ortamlarda kilitli dolap uygulamasına geçilmiş, arşive ve dosyalara erişim yetkisi sınırlandırılmıştır.</p>
            </ul>
            <br />

            <ul>
                <h2>  5. Kişisel Verilerinizi Ne Amaçla 3.Kişilere Aktarabiliriz?</h2>
                <p> CHEETAH, kural olarak kullanıcılar tarafından sağlanan veriler ile CHEETAH uygulaması ve cihazı ile arka planda toplanan sair verileri üçüncü kişilerle direkt olarak paylaşmayacaktır. </p>
                <p> CHEETAH, 6698 sayılı Kişisel Verilerin Korunması Kanunu uyarınca ve/veya ilgili mevzuattaki istisnaların varlığı halinde kullanıcılara ait verileri ayrıca rızalarını almaksızın işleyebilecek ve üçüncü kişilerle paylaşabilecektir. Bu durumlar aşağıda belirtilmiştir:</p>
                <p> Kanunlarda açıkça öngörülmesi,</p>
                <p>     Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması,</p>
                <p>     Sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, verilerin işlenmesinin gerekli olması,</p>
                <p>     CHEETAH’ın hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması,</p>
                <p>    Kullanıcılar tarafından alenileştirilmiş olması,</p>
                <p>     Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması,</p>
                <p>      Kullanıcılar’ın temel hak ve özgürlüklerine zarar vermemek kaydıyla, CHEETAH’ın meşru menfaatleri için veri işlenmesinin zorunlu olması.</p>
                <p>   Bununla birlikte, Müşterilere ait kişisel veriler, CHEETAH tarafından sunulan ürün ve hizmetlerden ilgili kişileri faydalandırmak için gerekli çalışmaların iş birimleri tarafından yapılması ve süreçlerin yürütülmesi, CHEETAH tarafından yürütülen ticari faaliyetlerin gerçekleştirilmesi için ilgili iş birimleri tarafından gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi, CHEETAH’ın ticari ve/veya iş stratejilerinin planlanması ve icrası, CHEETAH’ın ve CHEETAH ile iş ilişkisi içerisinde olan ilgili kişilerin (iş ortaklarının) hukuki, teknik ve ticari-iş güvenliğinin temini ile mevzuat uyarınca belirlenen kişisel veri işleme şartları ve amaçları çerçevesinde şirket yetkilileri, iş ortaklarımız ve kanunen yetkili kamu kurum ve kuruluşları ile özel kurumlar ile paylaşılabilecektir.</p>
                <p> Halihazırda,</p>
                <p>  Kimlik, iletişim ve lokasyon verileriniz; CHEETAH tarafından yürütülen faaliyetlerin gerçekleştirilmesi için şirketin meşru menfaati çerçevesinde çağrı merkezi çalışanlarımız ile,</p>
                <p>Kimlik, iletişim, lokasyon ve diğer verileriniz; kanunlarda açıkça yetki tanınması nedeniyle, şirket hissedarlarıyla,</p>
                <p>Kimlik ve finansal verileriniz; muhasebe departmanıyla,</p>
                <p>Kimlik ve finansal verileriniz; şirketin sözleşmeli hizmet aldığı 3.kişi mali müşavirlik hizmeti verenlerle,</p>
                <p>Kimlik, iletişim, lokasyon ve yasal yükümlülüğün ifası için gerekli olması halinde diğer verileriniz, şirketin hukuki işlerinin takibi için yasal yükümlülüğün ifası ve şirketin meşru menfaati kapsamında sözleşmeli hizmet alınan 3.kişi hukuksal danışmanlık bürolarıyla,</p>
                <p>Kimlik, finansal verileriniz, CHEETAH iş ortağı ödeme kuruluşu olan İyzico ile,</p>
                <p>Kimlik, iletişim, lokasyon ve uygulama üzerinde yer alan tüm kişisel verileriniz; uygulama güncellemeleri yapılabilmesi ve hizmetin gereği gibi ve güvenli ifası amacıyla meşru menfaat kapsamında şirketimiz iş ortağı sözleşmeli yazılım şirketi ile paylaşılmaktadır.</p>
                <p>CHEETAH tarafından toplanan kişisel verileriniz yukarıda detayları verilen CHEETAH’ın ticari ve/veya iş stratejilerinin planlanması ve icrası, CHEETAH tarafından yürütülen ticari faaliyetlerin gerçekleştirilmesi için ilgili iş birimlerimiz tarafından gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi, CHEETAH’ın ve CHEETAH ile iş ilişkisi içerisinde olan ilgili kişilerin hukuki, teknik ve ticari iş güvenliğinin temin edilmesi, CHEETAH tarafından sunulan ürün ve hizmetlerden ilgili kişileri faydalandırmak için gerekli çalışmaların iş birimlerimiz tarafından yapılması ve ilgili iş süreçlerinin yürütülmesi amaçlarıyla hissedarlarımıza, iş ortaklarımıza, tedarikçilerimizle, kanunen yetkili kamu kurumları ve özel kişiler ile diğer kişilerle Kanun’un 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde paylaşılabilecektir.</p>
            </ul>
            <br />

            <ul>
                <h2>6. Kişisel Verilerini Ne Kadar Süreyle Saklarız?  </h2>
                <p>CHEETAH, hizmetin ifası kapsamında topladığı veriler, üyelik devamınca metinde açıklanan gereklilikler süresince, mevzuatta belirtilen zamanaşımı süreleri dikkate alınarak saklanır. </p>
                <p> Kişisel Verilerin Silinmesi: Kanun kapsamında muhafaza edilen verileriniz; verilerinizin işlenmesinin gerektirdiği sebepler ortadan kalktığı takdirde talebiniz doğrultusunda veya re’sen silinecek, yok edilecek veya Kanun’un 7. maddesinin 3. fıkrasında bahsedilen Yönetmelik tarafından belirlenecek koşullar altında anonim hale getirilecektir.</p>
            </ul>
            <br />

            <ul>
                <h2>7.Politika’da Yapabileceğimiz Değişiklikler</h2>
                <p>CHEETAH, tek taraflı olarak Politika’da değişiklik yapabilir. CHEETAH’ın değişiklik yaptığı Politika hükümleri yayınlandığı tarihte yürürlük kazanır.</p>
            </ul>
            <br />

            <ul>
                <h2> 8. 6698 sayılı Kişisel Verilerin Korunması Kanunu’na  göre haklarınız nelerdir ve bu hakları nasıl kullanırsınız?</h2>
                <h>Kullanıcı;</h>
                <ul>
                    <li>Kişisel verilerinin işlenip işlenmediğini öğrenme,</li>
                    <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                    <li>Kişisel verilerinin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                    <li>Yurt içinde veya yurt dışında kişisel verilerinin aktarıldığı üçüncü kişileri bilme,</li>
                    <li>Kişisel verilerinin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                    <li>Kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel verilerinin silinmesini veya yok edilmesini isteme ve bu kapsamda ve kişisel verilerinin eksik veya yanlış işlenmiş olması halinde yapılan işlemlerin kişisel verilerinin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                    <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
                    <li>Kişisel verilerinin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması halinde zararın giderilmesini talep etme haklarına sahiptir.</li>
                </ul>
                <br />
                <p>Bu Aydınlatma Metni’ne dair herhangi bir sorunuz, yorumunuz veya isteğiniz varsa lütfen başvuru yönteminin 10.03.2018 tarih ve 30356 sayılı Veri Sorumlusuna Başvuru Usul Ve Esasları Hakkında Tebliğ’de yer alan usul ve esaslarına uygun olarak kimliğinizi gösteren belgeler ile birlikte "İSTASYON MAH. EBE NAZİRE KANAL SK. NO: 8 İÇ KAPI NO: 66 MERKEZ/ EDİRNE" adresine ıslak imzalı olarak; ya da info@cheetahscooters.com.tr adresine kayıtlı elektronik posta (KEP) adresi, güvenli elektronik imza, mobil imza ya da CHEETAH’ya daha önce bildirilen ve sistemimizde kayıtlı bulunan elektronik posta adresinizi kullanmak suretiyle başvurabilirsiniz. Başvurunuzda yer alan talepler, talebin niteliğine göre en geç 30 gün içerisinde ücretsiz olarak sonuçlandırılacaktır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi halinde, aşağıda belirtilen ücret alınabilecektir. Kişisel veri sahibinin başvurusuna Şirketimiz yazılı olarak cevap verecek ise on sayfaya kadar ücret alınmayacak ancak on sayfasının üzerindeki her sayfa için Kanun ve ilgili diğer mevzuatlarda belirtildiği şekilde 1 Türk Lirası işlem ücreti alabilecektir.</p>
                <p>CHEETAH, burada düzenlenen hususlarda her zaman değişiklik yapılabilir. Yapılan değişiklikler web sitemiz ve/veya Uygulama üzerinden yayınlanacaktır. Güncel metinlerin takibinden kullanıcılarımız sorumludur. </p>
            </ul>
        </div>
    )
}

