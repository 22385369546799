import React from 'react';
import "./terms.css";

export default function KullaniciSozlesmesi({ navigation }) {
    return (
        <div className="container-fluid py-5 termsBack"  >
            <ul>
                <h1>CHEETAH KULLANICI SÖZLEŞMESİ</h1>
            </ul>
            <br />
            <ul>
                <h2> MADDE 1- TARAFLAR</h2>
                <p>
                    İşbu Sözleşme aşağıdaki taraflar arasında akdedilmiştir:
                    Şirket merkezi İstasyon Mah. Ebe Nazire Kanal Sk. No:8 İç Kapı No: 66 Merkez / Edirne adresinde kayıtlı Cheetah Otomasyon Yazılım Arge ve Ulaşım Hizmetleri Limited Şti. (bundan böyle “CHEETAH” olarak anılacaktır.) ve mobil uygulamaya kayıt olurken  beyan edilen adreste yerleşik kullanıcı (bundan böyle kısaca “KULLANICI” olarak anılacaktır) arasında akdedilmiştir. (CHEETAH ve KULLANICI’dan her biri ayrı ayrı Taraf ve her ikisi birlikte Taraflar olarak anılacaktır.)
                </p>
            </ul>
            <br />

            <ul>
                <h2> MADDE 2- SÖZLEŞMENİN KAPSAMI   </h2>
                <p>
                    2.1. İşbu sözleşmenin amacı; CHEETAH mobil uygulamasının ve aracının kullanımına ilişkin hüküm ve şartların belirlenmesi ile Taraflar’ın karşılıklı hak ve yükümlülüklerinin tespitidir.
                </p></ul>
            <br />

            <ul>
                <h2>MADDE 3- TANIMLAR</h2>
                <p> 3.1. CHEETAH aracı: Kısa mesafeli ulaşımı daha kolay ve çevreye duyarlı hale getiren periyodik olarak şarj edilmesi gereken bir elektrikli scooter aracıdır.</p>
                <p>3.2. CHEETAH mobil uygulaması: Mikro mobilite araç kiralama uygulamasıdır.</p>
                <p> 3.3. CHEETAH: Elektrikli scooter paylaşım hizmetinin uygulama ve bakımından sorumlu olan lisans sahibi tüzel kişiyi.</p>
            </ul>
            <br />

            <ul>
                <h2> MADDE 4- SÖZLEŞMENİN ONAYLANMASI</h2>
                <p>4.1. CHEETAH mobil uygulamasına girilerek kişisel bilgiler doldurulduğunda “KULLANICI” olunur. KULLANICI, başvuru esnasında verdiği tüm bilgilerin eksiksiz, gerçeğe uygun ve güncel olduğunu beyan ve taahhüt eder.</p>
                <p>4.2. Her CHEETAH kullanıcısı, CHEETAH mobil uygulamasını kullanmaya başlamak için işbu Kullanıcı Sözleşmesi’ni kabul etmelidir. KULLANICI tarafından CHEETAH mobil uygulamasının kullanılmaya başlanmasıyla birlikte burada gösterilen tüm hükümler, KULLANICI tarafından kabul ve taahhüt edilmiş sayılır.</p>
                <p>4.3. KULLANICI, CHEETAH tarafından sunulan ürün ve hi̇zmetleri̇ kullanmaya başlamadan önce bu kullanıcı sözleşmesi̇ni̇ okuduğunu, elektroni̇k olarak verdi̇ği̇ onay i̇le sözleşmeni̇n tüm hükümleri̇ni̇ kabul etti̇ği̇ni̇ beyan etmektedir. CHEETAH uygulamasının ve araçların kullanımı i̇le tarafların hak ve yükümlülükleri̇ burada gösteri̇len hüküm ve şartlara tabi̇di̇r.</p>
            </ul>
            <br />

            <ul>
                <h2>  MADDE 5- CHEETAH KULLANICI SİSTEMİ</h2>
                <p>    5.1. KULLANICI, CHEETAH araçlarında başta araç takip sistemi olmak üzere coğrafi yer tanımlamasını sağlayan sistemler bulunduğunu bildiğini kabul eder. </p>
                <p>5.2. Kiralama Şekli: KULLANICI, CHEETAH mobil uygulaması üzerinden lokasyonuna en yakın araca erişebilmektedir. Kullanıma başlayabilmek için mobil uygulama üzerinden CHEETAH aracı üzerinde bulunan barkodun okutulması gerekmektedir. Yolculuk tamamlandıktan sonra KULLANICI mobil uygulama üzerinden aracı kilitleyeceğini ve aracın fotoğrafını çekerek sisteme yükleyerek yolculuğunu sonlandıracağını taahhüt eder. CHEETAH, araçların müsait olması koşuluyla hizmet verebilir. Herhangi bir saat aralığında müsait aracın mevcudiyetini garanti etmez. KULLANICI, diğer kullanıcıların hizmetten yararlanabilmesi için aracı zamanında ve eksiksiz teslim edeceğini kabul ve taahhüt eder. </p>
                <p>    5.3. KULLANICI, CHEETAH aracını bağlı olduğu üniversite kampüsü sınırlarında kullanıyorsa, kampüs kurallarına riayet etmekle, CHEETAH aracının kullanıma açık olduğu diğer alanlarda ise bu alanların kurallarına riayet etmekle ve ayrıca aracın kullanımının yasaklandığı ve tehlikeli olduğu bölgelerde aracı kullanmamakla sorumludur. Aksi takdirde doğacak her türlü zarardan tek başına sorumlu olduğunu kabul, beyan ve taahhüt eder. </p>
                <p>    5.4. Kullanım Süresi: Bir yolculuk için maksimum kiralama süresi kural olarak 24 saattir. KULLANICI, 24 saat içerisinde aracı kilitleyip yolculuğunu sonlandıracağını taahhüt eder. </p>
                <p>    24 saati aşan yolculuklar: Kiralama anından itibaren 24 saat geçmiş, KULLANICI’nın paket bakiyesi bitmiş, paket aşımı ile kullanım teklif edilmiş, KULLANICI tarafından yolculuk sonlandırılmamış ve paket aşım tutarları KULLANICI’nın sisteme kayıtlı kredi kartından tahsil edilememişse, CHEETAH tarafından araç çalıntı olarak kabul edilir. Bu durumda, CHEETAH, aracın tekrar kiralanamamasından doğan zararının tazminini KULLANICI’dan talep edebileceği gibi, aracın çalıntı olarak kabul edilmesi nedeniyle cezai kovuşturma için yasal yollara başvuruda bulunabilir. </p>
                <p>    5.5. Park Şekli: KULLANICI yolculuk tamamlandığında CHEETAH aracını yürürlükteki trafik kurallarına, kampüste kullanım halinde kampüs kurallarına uygun şekilde kamusal ve görünür bir alana park etmeyi kabul ve taahhüt eder. Aracın özel mülkiyet alanına, kilitli bir alana, kamusal olmayan herhangi bir alana yahut diğer kullanıcıların erişmesini engelleyecek herhangi bir alana park edilmesi durumunda meydana gelecek bütün hallerden ve aracın tekrar kiralanamamasından doğacak tüm zarardan KULLANICI sorumlu olur. Bu durumda CHEETAH’ın her türlü zararına ek olarak kullanıcı CHEETAH aracının kiralanamadığı her 24 saat için 50,00-TL cezai şart ödemeyi kabul ve taahhüt eder. KULLANICI, burada gösterilen cezai şart bedelinin paket bakiyesinden düşülebileceğini, ayrıca bildirdiği kredi kartı bilgileri kullanılarak tahsil edilebileceğini, talep ve dava edilebileceğini kabul ve taahhüt eder. </p>
                <p>    Bununla birlikte, KULLANICI’nın aracın trafik kurallarına aykırı park edilmesi gibi mevzuata aykırı davranışı nedeniyle doğacak cezai ve idari yaptırımlardan KULLANICI tek başına sorumlu olacaktır; açıklanan sebeplerle CHEETAH nezdinde bir zarar doğması halinde KULLANICI bu zararı tazmin etmekle yükümlü olduğunu kabul ve taahhüt eder. </p>
                <p>    5.6. Aracın Şarj Durumu: </p>
                <p>    5.6.1. Genel Olarak: Aracın şarj seviyesi CHEETAH mobil uygulaması üzerinden takip edilebilir. Araçtaki şarj seviyesinin kontrolü ve gidilecek mesafe için yeterli olduğunun tespit ve tayini KULLANICI’nın sorumluluğundadır. </p>
                <p>    KULLANICI; </p>
                <p>    –  Aracın şarj seviyesinin kullanımla azalacağını ve buna bağlı olarak aracın hızının ve operasyonel kabiliyetlerinin azalacağını, </p>
                <p>    – Şarj seviyesi kaybı oranının kullanıma, yol ve hava koşullarına göre değişebileceğini, </p>
                <p>    – KULLANICI hedef noktasına ulaşmamış olsa da, aracın, şarj gücünün %10 seviyesine gelmesiyle çalışmaz hale geleceğini, </p>
                <p>    – CHEETAH tarafından bu hususlarda KULLANICI’ya garanti verilmediğini ve bu nedenle kendisinin CHEETAH’dan herhangi bir nam altında herhangi bir talepte bulunamayacağını bildiğini kabul ve taahhüt eder. </p>
                <p>    5.6.2. KULLANICI, CHEETAH aracını kullanırken; çevreye, 3.kişilere ve diğer canlılara saygılı olacağını, aracın işletimiyle ilgili hususlarda (sürüş kuralları, park kuralları vb.) bisikletler ve elektrikli bisikletler için mevzuatta öngörülen kuralları bildiğini, bisikletler/elektrikli bisikletler için mevzuatta öngörülen trafik kuralları ve bunlarla sınırlı olmaksızın elektrikli scooterlar için sözleşmede ve mevzuatta düzenlenen/düzenlenecek tüm kuralları izleyeceğini, bu kurallara bağlı kalacağını, bu kurallara aykırı eylemi nedeniyle doğacak her türlü zarardan tek başına sorumlu olduğunu; KULLANICI’nın aracı mevzuata ve sözleşmeye aykırı kullanımı nedeniyle CHEETAH nezdinde bir zarar doğarsa bu zararı tazmin etmekle yükümlü olduğunu kabul ve taahhüt eder. </p>
                <p>    5.7. Ücretlendirme: </p>
                <p>    5.7.1. Kart Bilgilerinin Sisteme Girilmesi: KULLANICI, geçerli bir kart numarası ile talep edilecek ilgili kart bilgilerini, ödeme hizmeti altyapısını sağlayan iyzico Ödeme Hizmetleri AŞ. (Bundan böyle “iyzico” olarak anılacaktır) tarafından sağlanan sisteme girer. KULLANICI’nın yolculuğa başlayabilmesi için kart bilgilerini iyzico tarafından sunulan sisteme kaydetmesi gerekmektedir. Yolculuk sonunda hesaplanan toplam hizmet bedeli iyzico aracılığıyla tahsil edilmektedir. KULLANICI, işbu sözleşmeyi imzalamakla veya elektronik ortamda kabul etmekle, araç kullanım bedellerini kendisi tarafından verilen bilgileri kullanarak tahsil etmesi için iyzico’yu yetkilendirdiğini kabul eder. KULLANICI’nın kart bilgileri CHEETAH tarafından saklanmaz, kredi kartı bilgilerinin herhangi bir surette izinsiz kullanımı nedeniyle CHEETAH’ın sorumluluğu yoktur. Ödeme alt yapısı iyzico tarafından sağlanmaktadır. KULLANICI, kişisel bilgilerinin, hizmete ilişkin bilgilerin ve ödeme bilgilerinin iyzico’ya aktarılması ve iyzico tarafından kişisel verilerinin iyzico gizlilik politikasına göre işlenmesi ve saklanmasını kabul eder. KULLANICI, kart bilgileri ile ilgili değişiklikleri ve muhtemel kart hırsızlıklarını CHEETAH’a derhal yazılı olarak bildirmelidir. KULLANICI’nın geç bildiriminden doğacak doğrudan veya dolaylı zararlardan veya kart hırsızlıklarından CHEETAH sorumlu değildir; bahsi geçen sebeplerle CHEETAH nezdinde bir zarar doğması durumunda KULLANICI bu zararı tazmin etmekle yükümlüdür. </p>
                <p>    5.7.2.  Kullanım Bedeli: KULLANICI, CHEETAH mobil uygulama arayüzünde sunulan paketlerden kullanımına uygun olanı iyzico sistemini kullanarak kredi kartı ile satın alır. Bu madde hükmüne göre kullanım ücretleri kullanıcının paket bakiyesinden düşülür; paket bakiyesinin müsait olmaması halinde ise paket bedeli KULLANICI’ya ait kredi kartından iyzico tarafıdan tahsil edilir. </p>
                <p>       5.7.3.  Kredi Kartı Doğrulama İşlemi: CHEETAH, iyzico aracılığıyla, KULLANICI’nın paket bakiye tutarının/paket tutarının 10,00-TL veya 10,00-TL’den daha az olduğu hallerde, sisteme kayıtlı kullanıcı kredi kartından 2,00-TL tutarında deneme çekimi yapacaktır. Söz konusu deneme çekimi tutarının; KULLANICI tarafından hizmetin sonlandırılması halinde paket aşımı yapılmamışsa tamamı, kısmi paket aşımı yapılmışsa paket aşımı düşüldükten sonra kalan tutar KULLANICI hesabına iade edilir. Paket aşımı halinde ise, bu tutar KULLANICI’nın bakiye borcuna mahsup edilecektir. KULLANICI bu durumu bildiğini beyanla, deneme çekimi tutarını kabul ettiğini, paket aşımı halinde deneme çekimi tutarının borcuna mahsup edileceğini kabul eder. Bankacılık işlemleri nedeniyle deneme çekimi tutarının KULLANICI hesabına yansıması ortalama 3 (üç) iş günü içerisinde gerçekleşmektedir. KULLANICI, bu durumu bildiğini, geç iade nedeniyle CHEETAH’dan doğrudan veya dolaylı olarak herhangi bir hak, alacak ve tazminat talebinde bulunamayacağını kabul ve beyan eder. </p>
                <p>    5.7.4. Ücretlendirmenin Başladığı ve Sonlandığı An: Ücretlendirme işlemi, KULLANICI’nın mobil uygulama üzerindeki barkodu (QR kodu) sisteme okuttuğunda başlayacak, yolculuk tamamlanıp CHEETAH mobil uygulamasına KULLANICI tarafından fotoğraf yüklemesi yapıldığı anda son bulacaktır. </p>
                <p>    KULLANICI, fotoğraf yükleyerek yolcuğunu sonlandırmazsa, kullanım bedeli, satın aldığı paket üzerinden işlemeye devam eder. Paket aşımı halinde aşkın tutar KULLANICI’nın kayıtlı kredi kartından tahsil edilir. Yolculuğun 24 saati aşması ve KULLANICI kredi kartından paket aşımı tutarının tahsil edilememesi halinde, Sözleşme’nin 5.4. maddesi uygulama alanı bulacaktır. </p>
                <p>    KULLANICI, aracı kilitlemeyi unutması halinde, aracın 3. kişiler tarafından bilgisi dışında kullanıldığını ileri sürerek hak iddia edemez. </p>
                <p>   KULLANICI’nın , uygulamadan yahut telefondan kaynaklanan bir nedenle CHEETAH aracını iade edememesi hali bu kuralın istisnasıdır. Bu istisnanın uygulama alanı bulabilmesi için KULLANICI’nın durumu derhal CHEETAH’a bildirmesi, durumun CHEETAH tarafından onaylanması ve aracın CHEETAH tarafından hasarsız olarak kontrol edilerek teslim alınmış olması gerekmektedir. </p>
                <p>5.7.5. Paket Aşımı Hali: Bakiye Bitmesi Durumunda, CHEETAH, mobil uygulama üzerinden, paket bakiyesi bitmiş olan KULLANICI’nın yolculuğa devam etmesi halinde oluşan kullanım bedellerinin (paket aşımı) kayıtlı kredi kartından tahsil edilecektir. KULLANICI, bu durumu bildiğini, paket aşımı halinde doğacak hizmet bedelinin kayıtlı kredi kartından tahsil edilmesine onay verdiğini beyan eder. </p>
                <p> 5.7.6.  Kayıtlı Kredi Kartından Tahsilat Yapılamaması: Paket aşımı ile yolculuğuna devam eden KULLANICI’nın bakiye borcu kredi kartından birden fazla denemeye rağmen tahsil edilemezse, söz konusu bedel gecikmiş ödeme olarak kabul edilir ve Sözleşme’nin 5.7.8. maddesi gereğince geciken borca (kullanımı sonlandırdığı ancak kredi kartından çekim yapılamadığı ilk deneme tarihinden itibaren) temerrüt faizi işletilir. CHEETAH tarafından gecikme faizinin talep edilmemesi, hiçbir şekilde bu hakkından vazgeçtiği şeklinde yorumlanamaz. </p>
                <p>5.7.7.  Cezai Şart : Sözleşmeden doğan tazminat hakkı saklı kalmak üzere, CHEETAH; Hasarlı araç, kayıp araç, çalınan araç ve yanlış park gibi KULLANICI’dan kaynaklı hata ile mevzuata ve sözleşmeye aykırılık hallerinde aşağıda belirtilen cezai şart tutarlarını KULLANICI’dan tahsil eder. Bu halde KULLANICI, CHEETAH’dan  herhangi bir nam altında talepte bulunmayacağını kabul ve taahhüt eder. </p>
                <p>KULLANICI, CHEETAH’ın cezai şart ve tazminat talep hakkını saklı tuttuğunu kabul etmektedir.  KULLANICI’nın sözleşme ve mevzuat hükümlerine uygun davranmaması halinde aşağıda belirtilen cezai şart tutarları, CHEETAH tarafından iyzico’ya yazılı olarak bildirilecek, yapılan bildirim üzerine iyzico tarafından cezai şart bedeli KULLANICI’nın bakiyesinden mahsup edilecek; bakiyesinin mevcut olmaması halinde KULLANICI’ya ait kredi kartından tahsil edilecektir. Aksi halde KULLANICI, CHEETAH tarafından, sözleşmeye aykırılıktan doğan zararını tazmin hakkına ek olarak cezai şart tutarının da yasal yollara başvurularak talep ve tahsil edilebileceğini bildiğini kabul, beyan ve taahhüt eder. Bu halde KULLANICI, CHEETAH’dan herhangi bir nam altında talepte bulunmayacağını kabul ve taahhüt eder. CHEETAH, yasal süreçleri kendisinin yürüteceğini ve CHEETAH’dan herhangi bir talepte  bulunamayacağını kabul ve beyan eder. </p>
                <p>-KULLANICI tarafından, CHEETAH aracının, sözleşmede belirtilen kurallara, kampüs sınırları içinde kullanımı halinde kampüs kurallarına ve her durumda Karayolları Trafik Kanunu başta olmak üzere mer’i mevzuat hükümlerine uygun olarak park edilmemesi ya da kamu erişimine kapalı bir bölgeye veya özel mülk alanına park edilmesi halinde CHEETAH aracının kiralanamadığı her 24 saat için  50,00-TL cezai şart bedelini öder. </p>
                <p>- KULLANICI, CHEETAH uygulaması haritasında park için belirlenen alan içine park etmezse 50,00 TL cezai şart bedelini öder. </p>
                <p> - KULLANICI, yolculuk sonrasında uygulama üzerinden CHEETAH aracının tamamını kapsayacak şekilde fotoğrafını çekmek, bu şekilde yolculuğu sonlandırmakla mükelleftir. KULLANICI tarafından yolculuk sonunda CHEETAH aracının fotoğrafının net ve makul ölçüde kabul edilebilir şekilde çekilmemesi halinde 50,00TL cezai şart bedelini öder. </p>
                <p>- KULLANICI, CHEETAH aracını hasarlı bir şekilde teslim ederse hasar bedeline ek olarak 50,00TL cezai şart bedelini öder. </p>
                <p>- CHEETAH aracı tek kişiliktir. KULLANICI, CHEETAH aracını başka biriyle birlikte kullanır, bu durum CHEETAH tarafından öğrenilirse KULLANICI 100,00-TL cezai şart bedelini öder. </p>
                <p>- KULLANICI, CHEETAH’ı kaybetmesi veya çaldırması ve benzeri durumlarda CHEETAH nezdinde doğan zararın tazminat bedeline ek olarak 5.000,00-TL cezai şart bedelini CHEETAH’a ödeyecektir. </p>
                <p>5.7.8. Gecikme Faizi: KULLANICI’nın işbu Sözleşme’den doğan borçlarını zamanında ifa etmemesi veya bildirmiş olduğu kredi kartından ödeme alınamaması durumunda, geciken her ay için %5 gecikme faizi uygulanır. </p>
                <p>5.7.9.  Hesaplanan Hizmet Bedeline İtiraz: Yolculuk sonunda hesaplanan ve tahsil edilen/borç kaydedilen hizmet bedeline itiraz etmek isteyen KULLANICI, söz konusu itirazını yaklaşık yolculuk süresi ve kullanım zamanı ile ilgili tüm bilgi ve gerekçeleri içeren yazılı dilekçesiyle birlikte; CHEETAH’ın işbu sözleşmede belirtilen adresine 7(yedi) gün içerisinde bildirmekle mükelleftir. Süresi içinde yapılan itirazlar, CHEETAH tarafından ticari defter ve kayıtları, teknik kayıtları, araç takip sistemi kayıtları, CHEETAH mobil uygulaması sistem kayıtları, kullanım alanı kamera kayıtları incelenerek değerlendirilir. KULLANICI’nın itirazının haklı olmasının tespitinden itibaren 7 (yedi) gün içinde, hatalı tahsil edilen hizmet bedeli KULLANICI hesabına iade edilir. KULLANICI, geç iade nedeniyle CHEETAH’dan herhangi bir tazminat, faiz ve sair taleplerde bulanamayacağını bildiğini ve bu durumu kabul ettiğini beyan ve taahhüt eder. </p>
                <p>5.7.10.  KULLANICI’nın akıllı cep telefonunun çalınması yahut kaybedilmesi halinde derhal CHEETAH’a yazılı bildirimde bulunması ve sistemdeki bilgilerin silinmesini talep etmesi gerekmektedir. Aksi takdirde, üçüncü kişiler tarafından uygulamanın kullanılması durumunda CHEETAH’ın sorumlu tutulması mümkün değildir. </p>
            </ul>
            <br />
            <ul>
                <h2> MADDE 6 - KULLANICILIK ŞARTLARI</h2>
                <p> 6.1.Yaş Sınırı: Reşit olmayanlar CHEETAH Kullanıcısı olamaz.Veli / vasi onayı ile CHEETAH kullanıcılığı mümkün değildir.Kimlik bilgilerini yanlış doldurarak sisteme kaydolanlar hakkında 7. Madde hükmü uygulama alanı bulacaktır.</p>
                <p>  6.2.Kullanıcı Nitelikleri: CHEETAH kullanıcısı, İşbu Sözleşme’yi imzalamasıyla birlikte CHEETAH aracının kullanma şeklini ve temel trafik kurallarını bildiğini, fiziksel özellikleri itibariyle aracı kullanabileceğini ve 15 yaşından büyük ve mümeyyiz olduğunu peşinen kabul ve taahhüt eder.Araçların kullanım kılavuzu, CHEETAH mobil uygulaması üzerinde ayrıntılı olarak kullanıcılara sunulacaktır.</p>
                <p>     6.3.Kask ve Koruyucu Giysi Kullanımı: KULLANICI, elektronikli scooter aracının kullanımı ve çalışmasına ilişkin tüm kuralları takip etmek zorunda olup ilgili mevzuat uyarınca sürüş kurallarını da izlemekle mükelleftir.KULLANICI, CHEETAH aracını kullanırken uygun boyutlarda koruyucu kask, koruyucu giysi(görüşe engel olmayacak koruma gözlüğü, dizlik vb), aracın gece kullanımı halinde görünürlüklerini artıracak ve 3.kişiler tarafından fark edilmesini sağlanacak reflektif özelliğe sahip kıyafet ve aksesuar dahil güvenli sürüşün sağlanması için gerekli tüm aksesuarları edinmeli ve yolculuk sırasında kullanmalıdır.CHEETAH, herhangi bir kask ve koruyucu giysi markasının kalite ve güvenlik özelliklerini tavsiye, garanti ve taahhüt  etmez.Ürünün temini ve ürün tercihindeki risk KULLANICI’ya aittir.KULLANICI, koruyucu giysi gereklilikleri hususunda yerel trafik yasalarını izleyeceğini ve yasalara uygun davranacağını, koruyucu giysi tercihi veya koruyucu giysi kullanma tercihinden doğan sorumluluğun münhasıran kendi üzerinde olduğunu bildiğini kabul ve taahhüt eder.</p>
            </ul>
            <br />

            <ul>
                <h2>MADDE 7 - GENEL HÜKÜMLER</h2>
                <p>    7.1.CHEETAH, Sözleşme kapsamında yer alan kullanıcı yükümlülüklerini ve talimatlarını tek taraflı olarak değiştirme yetkisini haizdir.Mobil uygulama üzerinden ilan olunan veya CHEETAH tarafından uygun görülen iletişim araçlarıyla KULLANICI’ya yapılan değişiklik bildirimleri Taraflar bakımından bağlayıcıdır.</p>
                <p>        7.2.Kullanıcı ile İletişim: KULLANICI, işin gereği olarak CHEETAH’dan bilgilendirme mesajı(SMS) ve e - mail almayı kabul eder.KULLANICI ile iletişimin sağlıklı sürdürülebilmesi için kullanıcılık süresince bu durumun aksi talep konusu edilemez.İşbu maddede bahsedilen onay yalnızca bilgilendirme amaçlı elektronik iletileri kapsamakta olup, ticari elektronik ileti onayı değildir.</p>
                <p>    7.3.KULLANICI’nın Özenle Kullanım Borcu: KULLANICI, CHEETAH aracını azami özenle kullanmakla mükelleftir.KULLANICI, aracı teslim aldığı şekilde CHEETAH’a iade etmekle yükümlüdür.KULLANICI, CHEETAH araçlarının herhangi bir parçasını sökmemeyi ve değiştirmemeyi, araca ve herhangi bir parçasına zarar vermemeyi, araç üzerinde yer alan herhangi bir etiket ve yazıyı tahrip etmemeyi taahhüt eder.</p>
                <p>    Bu kurala aykırı hareketi sonucu doğacak tüm zararlardan tek başına sorumlu olur.Bununla birlikte, aykırı hareketin tespiti halinde KULLANICI ile CHEETAH arasındaki sözleşme CHEETAH tarafından tek taraflı olarak feshedilir.KULLANICI bir daha CHEETAH mobil uygulaması kullanıcısı olamaz.CHEETAH tarafından Sözleşme’nin feshedilmemesi zararını tazmin hakkından vazgeçtiği şeklinde yorumlanamaz.Buna karşın, kullanımdan kaynaklanan olağan yıpranmalardan KULLANICI sorumlu olmayacaktır.</p>
                <p>    7.4.Ticari Faaliyette Kullanma Yasağı: CHEETAH’ın açık yazılı izni olmaksızın aracın KULLANICI tarafından herhangi bir ticari amaç için kullanılması yasaktır.</p>
                <p>  7.5.CHEETAH’ın Kullanım Hakkı Devredilemez: KULLANICI kendisine tahsis edilen CHEETAH aracını 3.şahıslara kullandıramaz.Aksinin tespiti halinde Sözleşme’nin 7. Maddesi uygulama alanı bulur.Bu durumda meydana gelebilecek her türlü zarardan KULLANICI 3.kişi ile birlikte müteselsilen sorumludur.</p>
                <p>    7.6.KULLANICI, CHEETAH aracını suç sayılabilecek şekilde, gayri kanuni işlerde, alkol veya uyuşturucu madde etkisi altında kullanamaz.İşbu hükme aykırılık halinde Sözleşme’nin 7.maddesi uygulama alanı bulur.Bu durumda KULLANICI bir daha CHEETAH kullanıcısı olamaz.Bu durumda meydana gelebilecek her türlü zarardan KULLANICI sorumludur; CHEETAH nezdinde bir zarar doğması halinde ise KULLANICI bu zararı da tazmin etmekle yükümlüdür.</p>
                <p>     7.7.CHEETAH, Sözleşme’de anılan özelliklerin bulunduğunu taahhüt eder.Ancak, CHEETAH aracının bir önceki kullanıcı tarafından hasarlı olarak teslim edildiği anlaşılırsa, KULLANICI bu durumu derhal uygulama üzerinden yardım bileti oluşturarak CHEETAH’a bildirir.</p>
                <p>       KULLANICI, aracı kullanmadan önce temel güvenlik denetimini yapmakla mükelleftir. (Tekerlekler, tekerleklerin sağlamlığı, tüm fren ve ışıkların çalışır halde olması, yeterli akü şarjı, herhangi bir hasar belirtisi, olağandışı aşınma, diğer açık mekanik hasarlar ve bakım ihtiyacı).Arızalı olmasına rağmen aracı kullanan ve / veya durumu bildirmeyen KULLANICI daha sonra ücret iadesi talebinde bulunamaz.</p>
                <p>       Hasarlı aracı teslim alan KULLANICI bu durumu bildirmez, yolculuk sırasında bir kaza meydana gelirse, doğacak her türlü zarardan KULLANICI tek başına sorumlu olur.</p>
                <p>        7.8.KULLANICI, CHEETAH aracını kiraladığı sırada, başka bir CHEETAH aracı ile yahut farklı bir şekilde meydana gelen kaza sonucunda oluşan maddi hasar ve sair durumları derhal CHEETAH’a bildirir.CHEETAH yetkilileri gelene kadar aracın güvende kalması KULLANICI’nın sorumluluğundadır.KULLANICI, bu durumda CHEETAH’a yardımcı olacağını ve kolluk kuvveti ile yapılacak işlemlerde bizzat orada bulunacağını, kaza tutanağı tutulurken iştirak edeceğini kabul ve taahhüt eder.</p>
                <p>       7.9.KULLANICI, kendi kusuruyla meydana gelen kaza sonucunda oluşan hasar, masraf ve giderler, cezalar, yaralanma gibi maddi ve manevi kayıplardan tek başına sorumlu olduğunu ve CHEETAH’a rücu edemeyeceğini bildiğini kabul eder.Meydana gelen kaza sebebiyle CHEETAH nezdinde bir zarar meydana geldiğinde KULLANICI, bu zararı tazmin etmekle yükümlü olduğunu kabul ve taahhüt eder.Bahsi geçen zarar kalemleri aracın tamir masrafları, tamiri mümkün değilse aracın değeri, aracın kaza nedeniyle kullanılamamasından doğan zararlar, bu sözleşmede düzenlenmiş cezai şart bedeli ve sair zarar kalemleridir.</p>
                <p>      7.10.Üçüncü bir şahıs tarafından CHEETAH aracına bir zarar verilmesi yahut çalınması durumunda da KULLANICI bu durumu derhal CHEETAH’a bildirmekle yükümlüdür.Aksi takdirde KULLANICI doğacak her türlü zarardan kusuru oranında sorumlu olacaktır.CHEETAH aracının KULLANICI kusuru ile çalınması veya kaybolması halinde KULLANICI, aracın değerini, bu sözleşmede düzenlenmiş olan cezai şart bedelini, aracın çalınması / kaybolması nedeniyle kullanılamamasından doğan zararları ve sair zarar kalemlerin tazmin  etmekle yükümlü olduğunu kabul ve taahhüt eder.Bu halde CHEETAH tarafından iyzico’ya yazılı olarak bildirilecek, yapılan bildirim üzerine iyzico tarafından tazminat bedeli KULLANICI’nın bakiyesinden mahsup edilecek; bakiyesinin mevcut olmaması halinde KULLANICI’ya ait kredi kartından tahsil edilecektir.</p>
                <p>      7.11.CHEETAH, tek kişiliktir.KULLANICI, bu kurala aykırı eylemi neticesinde gerçekleşecek tüm zararlardan sorumludur.</p>
                <p>       7.12.CHEETAH’ın toplam taşıma kapasitesi azami 100 kg’dır.KULLANICI, CHEETAH’ı kullandığı sırada kendi ağırlığı da dahil olmak üzere toplam 100 kg taşıyabilir.KULLANICI bu kurala aykırı davranışı neticesinde meydana gelen her türlü zarardan tek başına sorumlu olur.Taşınan yükün(sırt çantası, evrak çantası, bilgisayar çantası) KULLANICI’nın vücudu ile bitişik şekilde konumlandırılması gerekmektedir.Aksi halde araç çalışmaz hale gelebileceği gibi, sürüş de güvenli olmayacak, doğacak tüm zarardan KULLANICI tek başına sorumlu olacaktır.</p>
                <p>       7.13.Yukarıdaki sözleşme hükmü olağan yük eşyaları(sırt çantası, bilgisayar çantası, evrak çantası vb.) hakkında düzenlenmiş olup, CHEETAH aracının yük taşımacılığı, herhangi bir vasıtayı çekme ve itme işlemi gibi ağır işlerde kullanılması yasaktır.KULLANICI, bu hükmü bildiğini kabul eder ve hükme aykırı davranışından meydana gelecek zararlardan tek başına sorumlu olur.</p>
                <p>       7.14.CHEETAH aracı, hız denemesi, motor sporları ve benzeri etkinliklerle kullanılamaz.Kullanılması halinde meydana gelebilecek tüm zarardan KULLANICI tek başına sorumlu olur.</p>
                <p>      7.15.CHEETAH aracı ile toplu taşıma araçlarına binmek, CHEETAH’ı hususi araçla taşımak işbu Sözleşme ile yasaklanmış olup KULLANICI’nın aksi davranışlarından doğacak her türlü zarardan münhasıran KULLANICI sorumlu olacaktır.</p>
                <p>       7.16.CHEETAH aracı kötü hava şartları ve kötü yol koşullarında kullanılmamalıdır.Hava ve yol şartlarına göre sürüş şeklini, fren mesafesini ve benzeri unsurları ayarlamak</p>
                <p>       KULLANICI’nın sorumluluğunda olup, bu kurala aykırı veya hatalı kullanımı neticesinde doğacak her türlü zarardan tek başına sorumluluğu doğacaktır.</p>
                <p>     7.17.CHEETAH yol durumundan ve çevresel şartlardan hiçbir şekilde sorumlu tutulamaz.</p>
                <p>     7.18.CHEETAH aracının kullanımı sırasında cep telefonu, müzik çalar, tablet gibi dikkat dağıtıcı aletlerin kullanımı yasaktır.KULLANICI’nın aksi davranışından doğan zararlardan CHEETAH sorumlu olmayacağı gibi KULLANICI’nın eylemi nedeniyle CHEETAH aracında oluşacak her türlü zarar KULLANICI tarafından tazmin edilecektir.</p>
                <p>     7.19.KULLANICI’nın ihmali veya kasti davranışı neticesinde 3.kişilerin görebileceği maddi ve manevi zararlardan CHEETAH doğrudan veya dolaylı olarak hiçbir şekilde sorumlu tutulamaz.</p>
                <p>      7.20.CHEETAH aracının KULLANICI tarafından bu sözleşme hükümlerine ve mevzuata aykırı kullanılması halinde KULLANICI, sorumluluğun sadece kendisine ait olduğunu ve CHEETAH nezdinde doğan veya doğacak her türlü zararı tazmin etmekle yükümlü olduğunu kabul, beyan ve taahhüt eder.</p>
                <p>      7.21.CHEETAH araçları CHEETAH’ın özel mülkiyetindedir; KULLANICI’nın veya üçüncü kişilerin borçları nedeniyle haczedilemez, alıkonulamaz veya üzerlerinde hapis hakkı kullanılamaz.Bahsi geçen durumların meydana gelmesi halinde KULLANICI, CHEETAH nezdinde doğan veya doğacak her türlü zararı tazmin etmekle yükümlüdür.</p>
            </ul>
            <br />

            <ul>
                <h2>MADDE 8 - SÖZLEŞMENİN FESHİ</h2>
                <p>8.1.Tarafların Talebi ile sözleşmenin feshi: Taraflar, bildirimsiz ve tek taraflı irade açıklamasıyla her zaman Sözleşme’yi feshedebilir.Sözleşmenin feshi halinde tarafların birbirinden olan alacaklarına ilişkin talep hakları saklıdır.</p>
                <p>8.2.KULLANICI’nın sözleşmeye ve Hukuka Aykırı Davranışı nedeniyle sözleşmenin feshi: KULLANICI’nın mevzuata, Sözleşme’ye ve Sözleşme’de yer alan talimatlara aykırı davranışının tespiti durumunda, CHEETAH tarafından KULLANICI’lık kaydı derhal silinir.KULLANICI’nın bakiye borcunun bulunduğunun anlaşılması halinde kalan borçları Sözleşme’ye uygun olarak tahsil edilir.Aracın KULLANICI tarafından mevzuata ve  sözleşmeye aykırı kullanımı nedeniyle CHEETAH’ın KULLANICI’dan tazminat ve cezai şart talep etme hakları saklıdır.</p>
                <p>8.3.Sözleşmenin feshi halinde KULLANICI, kullanımında olan CHEETAH aracını derhal sözleşmeye uygun bir yere bırakmakla yükümlüdür.</p>
            </ul>
            <br />

            <ul>
                <h2> MADDE 9 - BILGILERIN SAKLANMASI VE İSPAT YÜKÜMLÜLÜĞÜ</h2>
                <p>CHEETAH sisteminde kayıtlı kullanıcı bilgileri Cheetah Otomasyon Yazılım Arge ve Ulaşım Hizmetleri Limited Şti.KVK Politikası ve Veri Saklama ve İmha Politikası’na tabi olacak şekilde(10) yıl boyunca saklanır.İşbu Sözleşme’nin ifasından doğacak her türlü uyuşmazlıkta CHEETAH sisteminde saklanan veriler ile kullanıcı kayıtları, her türlü bilgi ve belge bağlayıcı ve kesin delil teşkil eder.</p>
            </ul>
            <br />

            <ul>
                <h2> MADDE 10 - FIKRI MÜLKIYET HAKLARI</h2>
                <p>10.1.CHEETAH uygulamasının logo, tasarım, imge, html kodu gibi telif hakkına tabi tüm unsurları BUGIWORKS Teknoloji Sanayi İç ve Dış Ticaret Ltd.Şti.’ne aittir.KULLANICI, bu unsurları ticari amaçlarla kullanamaz, çoğaltamaz, dağıtamaz, taklit edemez.</p>
                <p>10.2.CHEETAH’ın her türlü maddi ve fikri mülkiyet hakları da dahil olmak üzere malvarlığı, ayni ve şahsi hakları, ticari bilgi ve know - how’a yönelik tüm hakları saklıdır.</p>
                <p>10.3.KULLANICI tarafından bu başlık altında yer alan düzenlemelere aykırı davranılması durumunda KULLANICI derhal bu duruma son vermekle ve CHEETAH nezdinde doğmuş ve doğacak her türlü zararı tazmin etmekle yükümlü olduğunu kabul ve taahhüt eder.</p>
            </ul>
            <br />

            <ul>
                <h2> MADDE 11 - UYGULANACAK HUKUK VE YETKILI MAHKEME VE İCRA DAIRELERI</h2>
                <p>   İşbu Sözleşme Türkiye Cumhuriyeti kanunlarına tabidir.Sözleşme’nin ifasından doğabilecek her türlü uyuşmazlığın çözümünde İstanbul Çağlayan Mahkeme ve İcra Müdürlükleri yetkilidir.</p>
            </ul>
            <br />

            <ul>
                <h2>  MADDE 12 - TEBLIGAT ADRESLERI</h2>
                <p>12.1.CHEETAH’a yapılacak tüm tebligatlar İstasyon Mah.Ebe Nazire Kanal Sk.No: 8 İç Kapı No: 66 Merkez / Edirne adresine gönderilecektir.CHEETAH’a yapılacak tüm tebligatlar için, aracın kayıtlı olduğu CHEETAH’ın sistemde kayıtlı adresi CHEETAH’a ulaşılıp, info@cheetahscooters.com.tr mailinden temin edilerek, kayıtlı adresine gönderilecektir.</p>
                <p>    12.2.KULLANICI’nın, adres bilgilerini doldururken sisteme girdiği adres, KULLANICI’nın tebligat adresidir.</p>
                <p>12.3.Sözleşme’den doğacak tüm bildirimler bu adreslere yapılır.Taraflardan birinin adres değişikliği yapması halinde bu durumu derhal diğer tarafa yazılı olarak bildirilmesi gerekir.Aksi takdirde, eski adrese yapılan tebligatlar usule uygun olarak taraflara yapılmış kabul edilir.
                </p>
            </ul>
            <br />

            <ul>
                <h2>  MADDE 13 - DELIL SÖZLEŞMESI:</h2>
                <p>İşbu sözleşmeden doğacak tüm uyuşmazlıklarda, CHEETAH’ın ticari defter ve kayıtları, teknik kayıtları, araç takip sistemi kayıtları, CHEETAH mobil uygulaması sistem kayıtları, kullanım alanı kamera kayıtları, CHEETAH sisteminde kayıtlı kullanıcı bilgileri ve taraflar arasındaki yazılı bildirimler münhasır ve kesin delil olarak kabul edilecektir.</p>
            </ul>
            <br />

            <ul>
                <h2>    MADDE 14 - YÜRÜRLÜK:</h2>
                <p>    İşbu Sözleşme taraflar arasında KULLANICI’nın, kullanıcı kayıt formunu doldurmasından itibaren süresiz olarak yürürlüğe girer.Bu sözleşme taraflar arasında 2 nüsha olarak akdedilmiş olup, taraflar bu sözleşmede yer alan maddeleri okuduklarını, anladıklarını, kabul ve taahhüt ettiklerini açıkça beyan ederler.</p>
            </ul>

        </div >
    )
}
