import React, { useEffect } from "react";
import "./App.css";
import './App.scss';
import { motion } from "framer-motion"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

//Page 1 Assets
//import Animate from "./components/animateComponent/animate"
import cheetah from "./assets/pageOne/cheetah.png";
import driver from "./assets/pageOne/driver.png";
import hand from "./assets/pageOne/hand.png";
import appStore from "./assets/pageOne/appStore.png";
import googlePlay from "./assets/pageOne/googlePlay.png";

//Page 2 Assets
import CitaComponent from "./components/citaComponent/citaComponent";
import citaOne from "./assets/pageTwo/Cita1.png";
import citaTwo from "./assets/pageTwo/Cita2.png";
import citaThree from "./assets/pageTwo/Cita3.png";
import citaFour from "./assets/pageTwo/Cita4.png";
import citaFive from "./assets/pageTwo/Cita5.png";
import citaSix from "./assets/pageTwo/Cita6.png";
import citaSeven from "./assets/pageTwo/Cita7.png";
import citaEight from "./assets/pageTwo/Cita8.png";

//Page 3 Assets
import QuestionComponent from "./components/questionComponent/questionComponent";

//Page 4 Assets
import pageFourBack from "./assets/pageFour/pageFourBack.png";

//Page 5 Assets
import ContactComponent from "./components/contactComponent/contactComponent";
import phoneIcon from "./assets/pageFive/telephone.png";
import emailIcon from "./assets/pageFive/email.png";
import socialIcon from "./assets/pageFive/social-media.png";

//Footer Assets
// eslint-disable-next-line no-unused-vars
import Footer from "./components/footerComponent/footerComponent";
import footerLogo from "./assets/footer/footerLogo.png";
//import footerBackground from "./assets/footer/footerBackground.svg";
import Aydinlatma from "./assets/pageTerms/aydinlatma"
import Sozlesme from "./assets/pageTerms/sozlesme"
import AcikRizaMetni from "./assets/pageTerms/acikriza"
import CerezPolitikasi from "./assets/pageTerms/cerez"


import { gsap, Power1 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
//import { scale } from "style-value-types";


function App() {

  useEffect(() => {

    gsap.registerPlugin(ScrollTrigger)
    gsap.registerPlugin(MotionPathPlugin)


    const path = [
      // 1
      { x: "5vw", y: "-10vw" },
      { x: "35vw", y: "-30vw" }
    ]
    const scooter = document.querySelector('.driver')

    const tl = gsap.timeline({
      scrollTrigger: {
        scrub: 1,
        start: "top top",
        trigger: ".main",
        ease: "power1.inOut"
      },
    })
    tl.to(scooter, {
      motionPath: {
        path: path,
        trigger: ".main",
      },
      duration: 0.5,
      ease: Power1,
    })
    tl.to(scooter, {
      duration: 0.1
    }, 0)
    tl.to(scooter, {
      duration: 0.2
    }, 1)
    tl.to(scooter, {
      duration: 0.2
    }, 2)
    tl.to(scooter, {
      duration: 0.2
    }, 3)
    tl.to(scooter, {
      duration: 0.2
    })

  })

  return (
    <Router className="container enmain" id="enmain">
      <div className='overflow-scrolling'>
        <Switch>
          <Route path="/Acik-Riza-Metni">
            <AcikRizaMetni />
          </Route>
          <Route path="/Cerez-Politikasi">
            <CerezPolitikasi />
          </Route>
          <Route path="/Aydinlatma-Metni">
            <Aydinlatma />
          </Route>
          <Route path="/Kullanici-Sozlesmesi">
            <Sozlesme />
          </Route>
          <Route path="/" render={() => (
            <div className="container-fluid main " id="scrollStarts" >
              <div className="row">
                <div className="container pageOne">
                  <div className="componentOne">
                    <p className="title1">Yürümek için uzun</p>
                    <p className="title2">Cheetah için kısa! </p>
                    <div className="downloadComponent">
                      <a href="https://apps.apple.com/tr/app/Cheetah-scooter/id1575247597?l=tr" rel="nofollow noreferrer" target="_blank" >
                        <img
                          src={appStore}
                          alt="App Store Icon"
                          className="downloadIcon"
                        />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.bugiworks.citascooter" rel="nofollow noreferrer" target="_blank" >
                        <img
                          src={googlePlay}
                          alt="Google Play Icon"
                          className="downloadIcon"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="componentTwo">
                    <p className="title3">Uygulamayı indir </p>
                    <p className="title4">Cheetahlamaya şimdi başla! </p>
                  </div>
                </div>

                <div className="imagesComponent my-5 container " >
                  <img src={hand} alt="hand map" className="hand" />
                  <motion.img
                    src={driver}
                    alt="driver"
                    className="driver"
                  />
                  <img src={cheetah} alt="cheetah" className="cheetah" />
                </div>

                <div className="container my-5 pageTwo">
                  <div className="row my-5 mx-3 d-flex justify-content-center">
                    <CitaComponent
                      citaImg={citaOne}
                      citaText="Cheetah Uygulamasını İndir!"
                    />

                    <CitaComponent
                      citaImg={citaTwo}
                      citaText="Yakındaki Cheetah'ı bul!"
                    />
                    <CitaComponent citaImg={citaThree} citaText="QR Kodu Okut!" />

                    <CitaComponent
                      citaImg={citaFour}
                      citaText="Gaz ve Freni Kontrol Et!"
                    />

                    <CitaComponent citaImg={citaFive} citaText="Sürüşe Başla!" />

                    <CitaComponent
                      citaImg={citaSix}
                      citaText="Sürüş Sırasında Güvenliğine Dikkat Et!"
                    />

                    <CitaComponent citaImg={citaSeven} citaText="Sürüşü Sonlandır!" />

                    <CitaComponent citaImg={citaEight} citaText="Fotoğrafını Gönder!" />
                  </div>
                </div>

                <div className="container-fluid ">
                  <p className="questionTitle">Sıkça Sorulan Sorular</p>
                  <div
                    className="row  justify-content-center questionComponent ">
                    <QuestionComponent
                      questionText="En yakınımdaki Cheetah'ı nasıl görebilirim?"
                      answerText="Uygulamayı indirdikten sonra ana ekranda görülen haritadaki
             scooter ikonlarından sana en yakın Cheetah'ı bulabilirsin."
                    />
                    <QuestionComponent
                      questionText="İstediğim yere gidebilir miyim?"
                      answerText="Anasayfada gördüğün harita üzerinde işaretlenmiş alan sürüş
             yapabileceğin alanlardır. Bu alanın içerisinde bulunan kırmızı
             renkle işaretlenmiş alanlar da ise sürüş ve park yapmak
             yasaktır."
                    />
                    <QuestionComponent
                      questionText="Yolda şarjım biterse ne olur?"
                      answerText="Cheetah'ın şarjı bittiğinde gaz pedalı çalışmaz. En yakın alana
             park ederek sürüşünü sonlandırabilirsin."
                    />
                    <QuestionComponent
                      questionText="Sürüş sırasında bakiyem biterse ne olur?"
                      answerText="Yolculuğunun sonunda hesaplanan sürüş tutarı cüzdanında ki
             bakiyenden fazlaysa daha önce kaydettiğin kartından otomatik
             çekim yapılır. Eğer kaydettiğin kartında bakiye mevcut değilse
             cüzdanında eksi olarak görebilirsin."
                    />
                    <QuestionComponent
                      questionText="Ödeme Sistemi Güvenli mi?"
                      answerText="Uygulamada kaydettiğin kart bilgilerini iyzico altyapısıyla
             saklamaktayız. iyzico BDDK ve PCI-DSS lisanslarına sahip bir
             finans teknoloji şirketidir."
                    />
                    <QuestionComponent
                      questionText="Cheetah'ı istediğim yere park edebilir miyim?"
                      answerText="Yaya ve araç trafiği aksatmayacak şekilde uygulamada
             işaretlenen alanda dilediğin gibi park edebilirsin."
                    />
                    <QuestionComponent
                      questionText="Yolculuğumu nasıl sonlandırmalıyım?"
                      answerText="Öncelikle Cheetah'ı yaya ve trafik akışını etkilemeyecek bir
             yere park etmelisin. Daha sonra uygulamada bulunan BİTİR
             butonuna basıp fotoğrafını bizimle paylaşarak yolculuğunu
             bitirebilirsin.
"
                    />
                    <QuestionComponent
                      questionText="Sürüş sırasında güvende miyim?"
                      answerText="Güvenliğini sağlayabilmek için kask, dizlik gibi ekipmanları
             kullanmanı senden beklemekteyiz."
                    />
                  </div>
                </div>

                <div className="container-md d-flex justify-content-center">
                  <div className="pageFour">
                    <p className="pageFourTitle">Bu Cheetah Doğa Dostu</p>
                    <p className="pageFourDescription">
                      Cheetah ile yolculuk yapmak sadece keyifli değil aynı zamanda doğa
                      dostu! Cheetah'ı kullanarak hem karbon ayak izini azaltabilirsin.
                      Üstelik Cheetah sadece dostu değil sessizliği ve estetik görünüşü
                      sayesinde aynı zamanda da şehir dostu.
                    </p>
                    <p className="pageFourDescription2"> Hadi durma, sen de Cheetahla!</p>
                  </div>

                  <img src={pageFourBack} alt="citaEight" className="pageFourBack" />
                </div>

                <div className="container-lg ">
                  <p className="pageFiveTitle">Yardım mı istiyorsun?</p>
                  <div className="row">
                    <ContactComponent
                      contactImg={phoneIcon}
                      contactTitle="Telefon Numaramız:"
                      contactText="_"
                    />
                    <ContactComponent
                      contactImg={emailIcon}
                      contactTitle="E-Posta Adresimiz:"
                      contactText="info@cheetahscooters.com.tr"
                    />
                    <ContactComponent
                      contactImg={socialIcon}
                      contactTitle="Sosyal Medya Adreslerimiz:"
                      contactText="citascooters"
                    />
                  </div>
                  {/* FOOTER */}
                  <div className="footerComponent container-fluid ">
                    <div className="row footerItem align-items-center">
                      <Link
                        to="/Acik-Riza-Metni"
                        className="footerText1 col"
                      >
                        Açık Riza Metni
                      </Link>
                      <Link
                        to="/Aydinlatma-Metni"
                        className="footerText1 col"
                      >
                        Aydınlatma Metni
                      </Link>
                      <div className="footerIconComponent col">
                        <img
                          src={footerLogo}
                          alt="Footer Logo"
                          className="FooterIcon"
                        />
                        <p className="footerIconText">Powered by Hergele with love</p>
                      </div>
                      <Link
                        to="/Cerez-Politikasi"
                        className="footerText2 col"
                      >
                        Çerez Politikası
                      </Link>
                      <Link
                        to="/Kullanici-Sozlesmesi"
                        className="footerText2 col"
                      >
                        Kullanıcı Sözleşmesi
                      </Link>
                    </div>

                    <div className="footerBackground" alt="Footer Background" />
                  </div>
                </div>
              </div>
            </div>
          )}>
          </Route>
        </Switch>
      </div>
    </Router >

  );
}

export default App;

