import React, { useState } from "react";
import { motion } from "framer-motion";
import "./questionComponent.css";


function QuestionComponent(props) {
  const [icon, setIcon] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <motion.div className="col-md-5 QuestionComponent">
      <motion.div
        className="motion"
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.8 }}
        onClick={() => {
          if (isVisible ? setIsVisible(false) : setIsVisible(true));
          if (icon ? setIcon(false) : setIcon(true));
        }}
        animate={{
          x: icon ? "1vw" : "1vw",
          y: icon ? 0 : "-1vw",
          rotate: icon ? 20 : -90,
          opacity: icon ? 1 : 1,
        }}
        transition={{ duration: 0.3 }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36.241"
          height="41.396"
          viewBox="0 0 36.241 41.396"
          alt="opencloseIcon"
          className="questionIcon"
        >
          <defs>
            <filter
              id="Path_527"
              x="0"
              y="0"
              width="36.241"
              height="41.396"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="3" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="3" result="blur" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_527)">
            <path
              id="Path_527-2"
              data-name="Path 527"
              d="M4.126,3.575S1.687,3.157.736,5.022C-.217,6.907-.083,7.881.242,10.206s.413,3.04.514,2.9c.1-.125.5-1.8.921-2.729s.809-1.842.871-1.464a17.9,17.9,0,0,1-.911,3.454,7.154,7.154,0,0,0,.64,4.259,13.417,13.417,0,0,1,.894,2.039c.168.528.256.225.326-.745s.814-1.666.895-2.3a19.662,19.662,0,0,1,.353-2.893c.223-.62.861-1.465,1.031-1a12.717,12.717,0,0,1-.342,2.992c-.078.539-.837.705-.972,1.8a5.772,5.772,0,0,0,.2,3.211C5.129,20.588,5.734,21.518,6,22.1c.267.559.85,1.056.623.522a1.953,1.953,0,0,1-.016-1.664,4.138,4.138,0,0,0,.7-1.777c.06-.756.2-3.2.256-3.94s.442-.927.632-.436a6.261,6.261,0,0,1,.027,2.682,10.68,10.68,0,0,0-.774,2.59,2.619,2.619,0,0,0,1.821,2.557c1.276.265,1.318.524,1.508.683s.5.175.216-.7.143-2.076-.141-2.735-.684-1.076-.685-1.761c0-.7.306-1.549.726-1.129s.208.982.367,2.194c.158,1.231.341,2.015.964,2.321.624.287,1.96-.282,2.733-.213s1.628.6,1.545.178c-.082-.439-.139-.915-.219-1.648s.409-1.048.162-2.015-.746-1.768-1.677-1.9-2.571-.746-2.848-1.326.554-.757.9-.385.2.708.962.756c.755.067,2.308.051,2.886.627s.593,1.046.835.859a2.152,2.152,0,0,0,.451-1.688,4.2,4.2,0,0,0-1.471-1.91,14.306,14.306,0,0,0-3.358-.994,4.825,4.825,0,0,1-2.58-1.569c-.337-.528-.512-.763.038-.7a8.284,8.284,0,0,1,2.512,1.229,4.021,4.021,0,0,0,2.072.748,9.112,9.112,0,0,1,2.914.767c.339.371.1-.341-.209-1.021s-.32-2.012-1.639-2.614A10.757,10.757,0,0,0,12.057,7.5c-1.35,0-2.923.015-2.98-.618s1.4-.895,2.468-.556c1.069.358,1.263.889,2.053.921a13.1,13.1,0,0,0,3.031.1c.355-.213-.294-1.09-1.02-1.936s-1.561-2-2.574-2a39.954,39.954,0,0,1-4.267.055c-.352-.158.035-.681.5-.705a16.567,16.567,0,0,0,3.155-.2c.649-.258.9-.464.674-.567A5.31,5.31,0,0,0,8.739,0c-3,.045-3.581.056-4.191,1.452A3.626,3.626,0,0,0,4.126,3.575Z"
              transform="translate(9 6)"
              fill={icon ? "#c47672" : "#E98D56"}
            />
          </g>
        </svg>
      </motion.div>
      <motion.p
        className="questionText"
        whileHover={{ scale: 1.03 }}
        whileTap={{ scale: 1.05 }}
        onClick={() => {
          if (isVisible ? setIsVisible(false) : setIsVisible(true));
          if (icon ? setIcon(false) : setIcon(true));
        }}
      >
        {props.questionText}
      </motion.p>
      {isVisible ? (
        <motion.div
          className="answerText d-flex"
          animate={{
            y: isVisible ? "1vw" : "-1vw",
            opacity: isVisible ? 1 : 1,
          }}
          transition={{ duration: 0.2 }}
        >
          {props.answerText}
        </motion.div>
      ) : (
        <motion.div
          className="answerText position-absolute col-5 "
          animate={{
            y: isVisible ? "1vw" : "-1vw",
            opacity: isVisible ? 1 : 0,
          }}
          transition={{ duration: 0.2 }}
        >
          {props.answerText}
        </motion.div>
      )}
    </motion.div>
  );
}
export default QuestionComponent;
