import React from "react";
import "./citaComponent.css";

export default function CitaComponent(props) {
  return (
    <div className="col-6 col-lg-3 pageTwo  ">
      <img src={props.citaImg} alt="citaOne" className="CitaImg" />
      <p className="citaText">{props.citaText}</p>
    </div>
  );
}
