import React from 'react'
import "./terms.css"

export default function AcikRizaMetni() {
    return (
        <div className="container-fluid py-5 termsBack"  >
            <ul>
                <h2>     CHEETAH OTOMASYON YAZILIM ARGE VE ULAŞIM HİZMETLERİ LİMİTED ŞİRKETİ</h2>
                <p>“CHEETAH”</p>
                <h2>KULLANICI AÇIK RIZA BEYANI</h2>
                <p> Kişisel verilerimin, veri sorumlusu sıfatıyla CHEETAH OTOMASYON YAZILIM ARGE VE ULAŞIM HİZMETLERİ LİMİTED ŞİRKETİ (“CHEETAH”) veya gerekli güvenlik tedbirlerini aldırmak suretiyle yetkilendirdiği veri işleyenler tarafından; CHEETAH ile imzalamış olduğum kullanıcı sözleşmesinden doğan hak ve yükümlülüklerin yerine getirilmesi ve veri sorumlusu sıfatıyla CHEETAH’ın Türk Ticaret Kanunu Vergi Usul Kanunu ve ilgili diğer yasal mevzuat uyarınca hak ve yükümlülüklerinin yerine getirilmesi amaçlarıyla veya CHEETAH’ın meşru menfaati kapsamına giren nedenlerle; CHEETAH veya yetkilendirdiği veri işleyenlerin muhasebe, müşteri ile ilişkiler, yönetim departmanları tarafından fiziki, maddi yahut elektronik ortamda, sözlü veya yazılı şekilde toplanması yöntemiyle; kimlik verilerimin, iletişim verilerimin, lokasyon verilerimin, finansal verilerimin, kullanıcı işlem verilerimin, işlem güvenliği verilerimin, risk yönetim bilgisi verilerimin toplandığı ve aydınlatma metninde sayılan çalışan, hissedar, iş ortakları ve kamu kurum ve kuruluşlarla paylaşıldığı hususunda aydınlatıldım.</p>
                <p>Bu kere, CHEETAH tarafından hizmetin geliştirilmesi için site kullanımı, deneyimleri ve site içerisindeki sayfalara ilişkin tercihlerime ilişkin istatistiksel verilerimin çerezler ve uygulama araçları vasıtasıyla toplanmasını açık rızam ile kabul ederim.</p>
                <p> Bununla birlikte, yukarıda açık rızamla işlenmesine izin verdiğim işbu kişisel verilerimin yurtiçinde bulunan hissedarlarınızla, iş ortaklarınızla ve yetkili bayiliklerinizle  paylaşılmasını açık rızam ile kabul ederim.</p>
                <p>Kişisel verilerimin güvenliğinin sağlanması ve veri kayıplarının önlenmesini sağlamak ve aydınlatma metninde sayılan veri işleme amaçları çerçevesinde yurtdışındaki sunucularda saklanmak üzere aktarılmasını açık rızam ile kabul ederim.</p>
                <p>KVK Kanunu’nun 11.maddesi ve ilgili mevzuat uyarınca; CHEETAH’a başvurarak kendimle ilgili; kişisel veri işlenip işlenmediğini öğrenme, kişisel verilerim işlenmişse buna ilişkin bilgi talep etme, kişisel verilerimin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme, kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme, işbu verilerin işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerimin silinmesini veya yok edilmesini isteme, bu düzeltme ve silinme taleplerinin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, işlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kendi aleyhime bir sonucun ortaya çıkmasına itiraz etme, kişisel verilerimin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme haklarımın bulunduğunu ve bu hakları CHEETAH Veri Sahibi Başvuru Formunda belirtilen usulle talep edebileceğimi biliyorum.</p>
                <p>Ayrıca, işbu Sözleşme kapsamında paylaşılmış kişisel verilerin doğru ve güncel olduğunu; işbu bilgilerde değişiklik olması halinde değişiklikleri CHEETAH’a bildireceğimi kabul ve beyan ederim.</p>
                <p>Bu itibarla, yukarıda sıralanan kişisel verilerimin işlenmesine, ilgili süreç kapsamında işlenme amacı ile sınırlı olmak üzere kullanılmasına ve paylaşılmasına,gerektiği takdirde kişisel verilerimin anonimleştirilerek kişisel verilerimi anonimleştirerek başka verilerle eşleştirilerek dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hâle getireceğini ve gereken süre zarfında saklanmasına açık rızam olduğunu beyan ederek bu hususta tarafıma gerekli aydınlatmanın yapıldığını, Aydınlatma Metni’ni okuduğumu ve anladığımı beyan ederim.</p>
            </ul>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}
