import React from 'react'
import "./terms.css"

export default function CerezPolitikasi() {
    return (
        <div className="container-fluid py-5 termsBack"  >
            <ul>
                <h2>*İşbu metin, CHEETAH Kişisel Verilerin Korunması Politikası’nın eki mahiyetindedir.</h2>
                <p> Birçok web sitesi gibi CHEETAH, ziyaretçilerine daha etkin bir hizmet verebilmek, kişiselleştirilmiş hizmet vermek, Platform üzerindeki trafik analizini tespit etmek, kullanıcıların alışkanlıklarını tespit etmek gibi ve bunlarla sınırlı olmayan aşağıda ayrıntılarıyla izah edilecek amaçlarla çerez kullanmaktadır.</p>
                <p>CHEETAH ailesi olarak, tüm kişisel verilerinize ve veri güvenliğiniz bizim için büyük önem arz ettiğinden; işbu bilgilendirme metnini KVK Politikamız’ın eki olarak tarafınıza sunarız.</p>
                <p>Bu metinde özetle aşağıda sıralanan soruların cevapları ayrıntılı şekilde izah edilecektir.</p>
                <ul>
                    <li>Çerez nedir? Ne amaçla kullanılır?</li>
                    <li>CHEETAH hangi çerezleri ne amaçla kullanmaktadır?</li>
                    <li>Kullanıcı çerezlerini nasıl yönetebilir?</li>
                </ul>
            </ul>
            <br />

            <ul>
                <h2> 1. Çerez nedir? Ne amaçla kullanılır?</h2>
                <p>Çerez, bir siteyi ziyaretiniz sırasında; bilgisayarınıza veya mobil cihazınıza kaydedilen küçük boyutlu bir metin dosyalarıdır. Çerezler bir web sitesinin çalışması veya daha verimli çalışması veya web sitesinin sahiplerine bilgi sağlamak için kullanılmaktadır.</p>
                <p>Çerezler, CHEETAH’ı ziyaret ettiğinizde sizi tanıma, tercihlerinizi hatırlama ve ayarlarınız ile uyumlu kişiselleştirilmiş bir deneyim sunma, sitenin işlevselliğini arttırma, sitenin performansını tespit etmek gibi işlemler yapmak için kullanılabilir. Çerezler ayrıca CHEETAH ile etkileşimlerinizi daha hızlı ve daha güvenli hale getirir.</p>
            </ul>
            <br />

            <ul>
                <h2> 2. CHEETAH hangi çerezleri, ne amaçla kullanmaktadır?</h2>
                <p>  Kullanılan Çerez / Kullanım Amacı</p>
                <p>           Oturum Çerezi / Kullanıcının Platform’da kullanıcı bilgilerinin tanınmasını, kendi bilgilerinin sayfalara aktarılmasını sağlayan; böylelikle oturum süresince Platform’un düzgün işlemesini, kullanıcıların hizmetlerden yararlanabilmesini kolaylaştıran çerezlerdir.</p>
                <p>     Analiz Çerezleri / Kullanıcıların web sitesini ve mobil uygulamayı nasıl kullandığı hakkında bilgi almak suretiyle; web sitesini ve uygulamayı iyileştirmek, iletişimin tanıtımın etkinliğini sağlamak için kullanılır. Kullanıcılar tarafından açılan sayfalar, sayfaları ziyaret süreleri, kullanıcılara gönderilen elektronik postalara ilişkin bilgiler analitik çerezlere örnek olarak gösterilebilir. Sitemiz, Google Analitik çerezleri kullanmaktadır. Söz konusu çerezler ile toplanan veriler, ABD’de bulunan Google sunucularına aktarılmakta ve söz konusu veriler Google’ın veri koruma ilkeleri ile uyumlu olarak muhafaza edilmektedir. Google gizlilik politikaları ile ilgili bilgi almak için lütfen buraya tıklayınız.</p>
                <p>     Kalıcı Çerezler / Bu tür çerezler kullanıcı tercihlerini hatırlamak amacıyla toplanmaktadır. Böylece; size özel öneriler ve özelleştirilmiş hizmet sunmamız kolaylaşmaktadır.</p>
                <p>     Teknik Çerezler / Platform’un (web sitesinin ve mobil uygulamanın) doğru ve kesintisiz olarak çalışmasını sağlamaktadır.</p>
                <p>     Doğrulama Çerezleri / Kullanıcının her seferinde kullanıcı adı ve şifresini girmesine gerek kalmadan aynı tarayıcıda CHEETAH hizmetlerinden kolaylıkla yararlanabilmesini sağlayan çerezdir.</p>
                <p>     Kişiselleştirme çerezleri / Kullanıcıların tercihlerini hatırlamak için kullanılan çerezlerdir.</p>
            </ul>
            <br />

            <ul>
                <h2>3. Kullanıcı çerezleri nasıl yönetebilir?</h2>
                <p>    Kalıcı çerezleri veya oturum çerezlerini reddederseniz, web sitesini, mobil uygulamayı ve mobil sitesini kullanmaya devam edebilirsiniz fakat web sitesinin, mobil uygulamanın ve mobil sitesinin tüm işlevlerine erişemeyebilirsiniz veya erişiminiz sınırlı olabilir. Mobil uygulamada söz konusu durum değişkenlik gösterebilmektedir. </p>
                <p> İnternet tarayıcınızın çeşidine göre aşağıdaki adımları izleyerek, çerezler hakkında bilgi edinip, izin verme veya reddetme hakkınızı kullanabilirsiniz:</p>
                <p> Google Chrome:  Browser’ınızın “adres bölümünde” yer alan, “kilit işareti” veya “i” harfini tıklayarak, “Cookie” sekmesinden çerezlere izin verebilir veya bloke edebilirsiniz.</p>
                <p>İnternet Explorer: Browser’ınızın sağ üst köşesinde yer alan “Tool” veya “Araçlar” Bölümünden “Güvenlik” sekmesini tıklayın ve “izin ver” veya “izin verme” şeklinde çerez yönetimizi gerçekleştirin.</p>
                <p> Mozilla Firefox: Browser’ınızın sağ üst köşesinde yer alan “menüyü aç” sekmesini tıklayınız. “Seçenekler” görselini tıklayarak “Gizlilik ve Güvenlik” butonunu kullanarak çerez yönetiminizi yapınız.</p>
                <p> Diğer browserlar için de (opera, microsoft edge gibi), ilgili browser’ın yardım veya destek sayfalarını inceleyebilirsiniz.</p>
                <p>Safari: Telefonunuzun “Ayarlar” bölümünden “safari” sekmesini seçip, “Gizlilik ve Güvenlik” Bölümünden tüm çerez yönetiminizi sağlayabilirsiniz.</p>
            </ul>

        </div>
    )
}
