import React from "react";
import "./contactComponent.css";

export default function ContactComponent(props) {
  return (
    <div className="col-lg-4 pageFive">
      <img src={props.contactImg} alt="phoneIcon" className="pageFiveIcons " />
      <p className="pageFiveIconText">{props.contactTitle}</p>
      <p className="pageFiveIconText2">{props.contactText}</p>
    </div>
  );
}
